<template>
  <v-card>
    <h1>H1 Heading over form</h1>
    <v-form ref="form">
      <v-text-field value="i am a text field" label="What do you like at parkingcrew?"></v-text-field>

      <v-textarea value="i am a text area" label="Describe your history with parkingcrew?"></v-textarea>

      <v-select :items="selectItems" label="What is your preferred innovation?" />

      <v-radio-group v-model="radioGroup" label="How much cash do you make a year?">
        <v-radio value="low" label="less then 1000" />
        <v-radio value="medium" label="from 1000 to 50000" />
        <v-radio value="high" label="more then 50000" />
      </v-radio-group>

      <p>Which tools do you know?</p>
      <v-checkbox label="Advanced Search" value="Advanced Search"></v-checkbox>
      <v-checkbox label="ShotTimeCache" value="Short Time Cache"></v-checkbox>

      <v-btn @click.prevent="save">Normal button</v-btn>
    </v-form>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      selectItems: ['AdvancedSearch', 'ShortTimeCache', 'DoYouFeelTheForce'],
      radioGroup: null
    }
  },
  methods: {
    save() {
      this.$store.dispatch('snackbar/showSuccessMessage', { message: 'Data was send' })
    }
  }
}
</script>
